import React from "react";
import SEO from "../components/seo/seo";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import FadeIn from "../components/animations/FadeIn";

export const query = graphql`query ($slug: String!) {
  allHorizontalMachinesJson(slug: {eq: $slug}) {
    title
    subTitle
    characteristics
    about
    table {
      tableL
      tableR
    }
    canFormPack
    canDoPackaging {
      name
      linkTo
    }
    canFormModificationPack
    canDoModificationPackaging {
      name
      linkTo
    }
    canFormProduct
    canDoProducts {
      name
      linkTo
    }
    remoteAccess
    compatible
    compatibleWithDosing {
      name
      linkTo
      compatibleWithDosingImage {
        childImageSharp {
          gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
    image01 {
      childImageSharp {
        original {
          src
        }
      }
    }
    image02 {
      childImageSharp {
        original {
          src
        }
      }
    }
    image03 {
      childImageSharp {
        original {
          src
        }
      }
    }
    iconPack {
      childImageSharp {
        gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: NONE)
      }
    }
    iconProducts {
      childImageSharp {
        gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: NONE)
      }
    }
    iconRemote {
      childImageSharp {
        gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: NONE)
      }
    }
  }
}
`;

const AllHorizontalMachine = ({ data, intl }) => {
	const hMachine = data.allHorizontalMachinesJson;

	const graphImage1 = hMachine.image01.childImageSharp.original.src;
	const graphImage2 = hMachine.image02.childImageSharp.original.src;
	const graphImage3 = hMachine.image03.childImageSharp.original.src;
	const images = [
		{
			original: graphImage1,
			thumbnail: graphImage1,
			originalAlt: 'machine Image'
		},
		{
			original: graphImage2,
			thumbnail: graphImage2,
			originalAlt: 'machine Image'
		},
		{
			original: graphImage3,
			thumbnail: graphImage3,
			originalAlt: 'machine Image'
		},
	];

	return <>
        <SEO title={intl.formatMessage({ id: "machineSelection.horizontal" })} />

        {/* ----------------------------------------------------    H O M E     ------------------------------------------------ */}

        <div className="gridAllMachinesCreatePagesHome">
            <div className="boxAllMachinesCreatePagesHomeSideText">
                <FadeIn duration="2s" delay="0.5s"><SideVerticalText /></FadeIn>
            </div>
            <div className="boxAllMachinesCreatePagesHomeTopText">
                <div className="allMachinesSecondLevelTitle">
                    <p>
                        <FormattedMessage id={hMachine.title} />
                    </p>
                </div>
                <div className="allMachinesSecondLevelSubTitle">
                    <div className="allAllMachinesHorizontalCircle"></div>
                    <p className="allAllMachinesCircleText">
                        <FormattedMessage id={hMachine.subTitle} />
                    </p>
                </div>
            </div>
            <div className="boxAllMachinesCreatePagesHomeLeft">
                <ImageGallery
                    items={images}
                    lazyLoad={false}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    useBrowserFullscreen={false}
                    showThumbnails={false}
                    showNav={true}
                    showBullets={false}
                    autoPlay={false}
                    slideDuration={1000}
                    /*slideInterval={10000} */
                />
            </div>

            <div className="boxAllMachinesCreatePagesHomeRight">
                <div className="allAllMachinesTableWrapper">
                    <p className="allAllMachinesCharacteristics">
                        <FormattedMessage id={hMachine.characteristics} />
                    </p>
                    <table>
                        <tbody>
                            {hMachine.table.map((item, index) => {
                                const { tableL, tableR } = item;
                                return (
                                    <tr key={index} className="allAllMachinesTable">
                                        <td className="allAllMachinesTableTextLeft">
                                            <FormattedMessage id={tableL} />
                                        </td>
                                        <td className="allAllMachinesTableTextRight">
                                            <FormattedMessage id={tableR} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="boxAllMachinesCreatePagesHomeEmptyRight"></div>
            <div className="boxAllMachinesCreatePagesHomeBottomText">
                <BottomFactory />
            </div>
        </div>

        <div className="allAllMachinesSection02">
            {/* ----------------------------------------------------    A B O U T  Section     ------------------------------------------------ */}
            <div className="allAllMachinesAboutBox">
                <div className="allAllMachinesMainTextWrapper">
                    <FormattedMessage id={hMachine.about} />
                </div>

                {hMachine.remoteAccess.includes(" ") ? (
                    " "
                ) : (
                    <div className="allAllMachinesRemoteDiv">
                        <div className="allAllMachinesRemoteIconDiv">
                            <GatsbyImage
                                image={hMachine.iconRemote.childImageSharp.gatsbyImageData}
                                className="allAllMachinesRemoteIcon"
                                alt="allAllMachinesImage"
                                loading="eager" />
                        </div>

                        <p className="allAllMachinesRemoteText">
                            <FormattedMessage id={hMachine.remoteAccess} />
                        </p>
                    </div>
                )}
            </div>

            {/* ----------------------------------------------------    DosingCompatible Section     ------------------------------------------------ */}

            <div className="allMachinesFilterSection">
                {hMachine.compatibleWithDosing.some(() => {
                    const isTrue = [];
                    if (isTrue) {
                        return true;
                    }
                    return null;
                }) ? (
                    <div className="filterSectionInside">
                        <div className="filterSectionTitle">
                            <div className="allAllMachinesWeighingDevicesCircle"></div>
                            <p>
                                <FormattedMessage id={hMachine.compatible} />
                            </p>
                        </div>
                        <div className="filterSectionMap">
                            {hMachine.compatibleWithDosing.map((item, index) => {
                                const { name, linkTo } = item;
                                return (
                                    <div key={index} className="filterSectionCard">
                                        <Link to={linkTo}>
                                            <GatsbyImage
                                                image={item.compatibleWithDosingImage.childImageSharp.gatsbyImageData}
                                                className="filterSectionCardImage"
                                                alt="filterSectionCardImage"
                                                loading="eager" />
                                            <span className="filterSectionCardText">
                                                <FormattedMessage id={name} />
                                            </span>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    " "
                )}
            </div>

            {/* ----------------------------------------------------    Packing Section     ------------------------------------------------ */}
            <div className="allMachinesFilterProPackSection">
                <div className="allMachinesFilterPackSection">
                    {hMachine.canDoPackaging.some(() => {
                        const isTrue = [];
                        if (isTrue) {
                            return true;
                        }
                        return null;
                    }) ? (
                        <div className="allMachinesFilterProPackSectionInside">
                            <div className="filterProPackSectionTitle">
                                <p>{intl.formatMessage({ id: "navbar.packaging" })}</p>
                            </div>

                            <div className="filterProPackSectionBox">
                                <div className="filterProPackSectionBoxImageDiv">
                                    <GatsbyImage
                                        image={hMachine.iconPack.childImageSharp.gatsbyImageData}
                                        className="filterProPackSectionBoxImage"
                                        alt="allAllMachinesCardIcon"
                                        loading="eager" />
                                </div>
                                <div className="filterProPackSectionBoxDataDiv">
                                    <div className="cardSectionProPack">
                                        <p>
                                            <FormattedMessage id={hMachine.canFormPack} />
                                        </p>
                                        {hMachine.canDoPackaging.map((item, index) => {
                                            const { name, linkTo } = item;
                                            return (
                                                <div key={index}>
                                                    <li className="filterProPackSectionCardText">
                                                        <Link to={linkTo}>
                                                            <div className="sectionCardIcon">
                                                                <IoMdArrowDroprightCircle
                                                                    size="24"
                                                                    className="filterProPackSectionCardArrow"
                                                                />
                                                            </div>
                                                            <FormattedMessage id={name} />
                                                        </Link>
                                                    </li>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {hMachine.canDoModificationPackaging.some(() => {
                                        const isTrue = [];
                                        if (isTrue) {
                                            return true;
                                        }
                                        return null;
                                    }) ? (
                                        <div className="cardSectionProPack">
                                            <p>
                                                <FormattedMessage
                                                    id={hMachine.canFormModificationPack}
                                                />
                                            </p>
                                            {hMachine.canDoModificationPackaging.map(
                                                (item, index) => {
                                                    const { name, linkTo } = item;
                                                    return (
                                                        <div key={index}>
                                                            <li className="filterProPackSectionCardText">
                                                                <Link to={linkTo}>
                                                                    <div className="sectionCardIcon">
                                                                        <IoMdArrowDroprightCircle
                                                                            size="24"
                                                                            className="filterProPackSectionCardArrow"
                                                                        />
                                                                    </div>
                                                                    <FormattedMessage id={name} />
                                                                </Link>
                                                            </li>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    ) : (
                                        " "
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        " "
                    )}
                </div>

                {/* ----------------------------------------------------    Products Section     ------------------------------------------------ */}

                <div className="allMachinesFilterProductsSection">
                    {hMachine.canDoProducts.some(() => {
                        const isTrue = [];
                        if (isTrue) {
                            return true;
                        }
                        return null;
                    }) ? (
                        <div className="allMachinesFilterProPackSectionInside">
                            <div className="filterProPackSectionTitle">
                                <p>{intl.formatMessage({ id: "navbar.products" })}</p>
                            </div>

                            <div className="filterProPackSectionBox">
                                <div className="filterProPackSectionBoxImageDiv">
                                    <GatsbyImage
                                        image={hMachine.iconProducts.childImageSharp.gatsbyImageData}
                                        className="filterProPackSectionBoxImage"
                                        alt="allAllMachinesCardIcon"
                                        loading="eager" />
                                </div>
                                <div className="filterProPackSectionBoxDataDiv">
                                    <div className="cardSectionProPack">
                                        <p>
                                            <FormattedMessage id={hMachine.canFormProduct} />
                                        </p>
                                        {hMachine.canDoProducts.map((item, index) => {
                                            const { name, linkTo } = item;
                                            return (
                                                <div key={index}>
                                                    <li className="filterProPackSectionCardText">
                                                        <Link to={linkTo}>
                                                            <div className="sectionCardIcon">
                                                                <IoMdArrowDroprightCircle
                                                                    size="24"
                                                                    className="filterProPackSectionCardArrow"
                                                                />
                                                            </div>
                                                            <FormattedMessage id={name} />
                                                        </Link>
                                                    </li>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        " "
                    )}
                </div>
            </div>
        </div>
    </>;
};

export default injectIntl(AllHorizontalMachine);
